.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 14px;
  background-color: rgba(38, 34, 34, 0.95);
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 26px;
  color: #ffffff;
  align-items: center;
}

.content {
  flex: 1;
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.link {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  display: inline-block;
}

.link:hover,
.link:active,
.link:focus {
  color: #d90101;
  text-decoration: underline;
}

.button {
  background-color: #d90101;
  border: 2px solid transparent;
  border-radius: 0px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  margin: 15px;
  display: block;
  min-width: 140px;
  font-size: inherit;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .button {
    margin-top: 0;
  }
}
